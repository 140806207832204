<template>
  <div class="container">
    <div class="row">
      <div class="rc-box-col-15 rc-text-centre">
        <h1>Portal Home</h1>
      </div>
    </div>
    <div class="row rc-background-dark">
      <div class="rc-box-col-10">
        <div>
          <p>Welcome to the ReadyChek portal, this is where you can manage your vehicles</p>
          <p>To get started click on the "Vehicles and Trailers" link in the toolbar, 
          from that page you can edit each vehicle in your fleet.</p>
          <p>Here are some simple statistics from the company information on record.</p>
        </div>
        <div class="container">
          <div class="row">
            <div class="rc-box-col-2"></div>
            <div class="rc-box-col-12 rc-text-centre">
              <div class="rc-font-bold">Statistics</div>
            </div>
          </div>
          <div v-if="inspections" class="row">
            <div class="rc-box-col-2"></div>
            <div class="rc-box-col-6 rc-text-centre rc-background-dark">
              <p class="rc-font-bold">Inspections this month</p>
              <div class="rc-font-extra-large">{{ companyStats.inspectionsThisMonth }}</div>
            </div>
            <div class="rc-box-col-6 rc-text-centre rc-background-dark">
              <p class="rc-font-bold">Number of</p>
              <div class="container">
                <div class="row">
                  <div class="rc-box-col-8">
                    Vehicles
                    <div class="rc-font-extra-large">{{ companyStats.vehicleCount }}</div>
                  </div>
                  <div class="rc-box-col-7">
                    Trailers
                    <div class="rc-font-extra-large">{{ companyStats.trailerCount }}</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="rc-box-col-2"></div>
            <div class="rc-box-col-12 rc-text-centre rc-background-dark">
              <p class="rc-font-bold">ReadyChek Users</p>
              <div class="rc-font-extra-large">{{ companyStats.userCount }}</div>
            </div>
          </div>
        </div>
      </div>
      <div class="rc-box-col-5 rowEven">
        <p class="rc-font-bold w3-center">What's new!</p>
        <div class="rowOdd">
          <div class="container">

            <div class="row rowEvent"><!-- start row -->
              <div class="rc-box-col-1"></div>
              <div class="rc-box-col-13">
                <p>In version 1.2.5 released May 19, 2021</p>
                <ol>
                  <li>Added the ability to add independent remarks to an inspection report</li>
                  <li>Upload vehicle and trailer images in HEIC (iPhone) format</li>
                </ol>
                <p>ReadyChek Product Team</p>
              </div>
              <div class="rc-box-col-1"></div>
            </div> <!-- end row -->

            <div class="row rowEvent"><!-- start row -->
              <div class="rc-box-col-1"></div>
              <div class="rc-box-col-13">
                <p>In version 1.1.21 released Apr 25, 2021</p>
                <ol>
                  <li>Improved email communication/formatting around inspections, updates and repairs</li>
                  <li>Ability to re-invite a user (in cases where users no longer have their original invite email)</li>
                  <li>Added Billing contact email</li>
                  <li>Other minor bug fixes</li>
                </ol>
                <p>ReadyChek Product Team</p>
              </div>
              <div class="rc-box-col-1"></div>
            </div> <!-- end row -->
            
            <div class="row rowEvent"><!-- start row -->
              <div class="rc-box-col-1"></div>
              <div class="rc-box-col-13">
                <p>In version 1.1.20 released Apr 14, 2021</p>
                <ol>
                  <li>Allows download of inspection PDF files from portal for immediate pdf access of specific inspections</li>
                  <li>Enforced brake selection when adding vehicles helps drivers update inspection appropriately regarding brake types</li>
                  <li>Improved "Add Vehicle" page to make adding trailers more intuitive and easier.</li>
                  <li>Improved naming standard for pdf files - yyyy-mm-dd-truckname-plate-drivername-uniqueIdentifier.pdf</li>
                  <li>Added Billing section for updating billing and invoice email instructions</li>
                </ol>
                <p>ReadyChek Product Team</p>
              </div>
              <div class="rc-box-col-1"></div>
            </div> <!-- end row -->
            
            
          </div>
          
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'portal-home',
  components: {
    // HelloWorld
  },
  computed: {
    ...mapGetters([
      'auth_connected',
      'inspections_found',
      'vehicles_active',
      'employees_found',
      'categorys_found',
      'company_stats',
      'domain',
    ]),
    companyStats: function () {
      return this.company_stats.list[0] || {};
    },
    inspections: function() {
      var result = false;
      if (this.domain && this.domain.session()) {
        result = this.domain.session().company().features().find().allowsInspections();
      }
      return result;
    }
  },
  mounted: function () {
    if (!this.auth_connected) {
      this.$router.replace({ name: this.$route.query.redirectTo || 'signin' });
    }
  },
  methods: {},
};
</script>